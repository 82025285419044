import React from 'react';
import Container from 'common/source/components/UI/Container';

import CategoryGrid from 'common/source/containers/SPCategoryList';
import SolutionsCategoryBodyWrapper from './solutionscategory.style';

const SolutionsCategoryBody = ({ node }) => {
  const {
    html,
    frontmatter: { categories }
  } = node;
  return (
    <SolutionsCategoryBodyWrapper>
      <CategoryGrid categories={categories} />
      <Container>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </SolutionsCategoryBodyWrapper>
  );
};

export default SolutionsCategoryBody;
