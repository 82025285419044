import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
import Footer from 'common/source/containers/Footer';

import BannerCatalog from 'common/source/containers/BannerCatalog';
import SolutionsBody from 'common/source/containers/SolutionsBody';
import SEO from '../../components/SEO';
import config from '../../../data/SiteConfig';

class SolutionsPage extends React.Component {
  render() {
    const { data } = this.props;
    const { page } = data;
    const { frontmatter } = page;
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO pageTitle={`Soluciones | ${config.siteTitle}`} path="/soluciones" pageNode={page} />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar background="blue" />
            <BannerCatalog cover={frontmatter.cover} title={frontmatter.title}>
              {frontmatter.subtitle}
            </BannerCatalog>
            <SolutionsBody node={page} />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}
export default SolutionsPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query SolutionsQuery {
    page: markdownRemark(frontmatter: { slug: { eq: "soluciones" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        slug
        title
        subtitle
        type
        cover
        thumbnail
        categories {
          category_slug
          title
          subtitle
          featured
          cover
          thumbnail
        }
      }
    }
  }
`;
